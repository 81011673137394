import React, { useState, useEffect } from "react"
import { useLocation } from '@reach/router'
import { Link } from "gatsby"
import { graphql } from "gatsby"

import API from '../api/index'

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Loader from "../components/addon/Loader"

const Search = ({ data }) => {

    const metaTag = []
    const location = useLocation()
    const [dataArr, setDataArr] = useState([])
    const [specialityArr, setSpecialityArr] = useState([])
    const [sitemapMenu, setSitemapMenu] = useState(0)

    const sitemapGetData = () => {
        API.sitemapMenu()
        .then(res => {
            if(res){
                setSitemapMenu(res)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    const formatData = () => {
        if(data) {
            const sitemapData = data?.allNodeTemplatePages?.nodes
            const specialityData = data?.allTaxonomyTermSpeciality?.nodes
            let arr = []
            specialityData.forEach((el, index) => {
                let obj = {
                    field_section: el?.field_section,
                    data: [el]
                }
                if(arr?.length) {
                    let oldType = arr.findIndex(arrEl => arrEl?.field_section === el?.field_section)
                    if(oldType !== -1) {
                        arr[oldType].data.push(el)
                    } else {
                        arr.push(obj)
                    }
                } else {
                    arr.push(obj)
                }
            })
            setSpecialityArr(arr)

            let arr1 = []
            sitemapData.forEach((el, index) => {
                let obj = {
                    field_section: el?.relationships?.field_sitemap_category?.name || "OTHERS",
                    data: [el]
                }
                if(arr1?.length) {
                    let oldType = arr1.findIndex(arrEl => arrEl?.field_section === obj?.field_section)
                    if(oldType !== -1) {
                        arr1[oldType].data.push(el)
                    } else {
                        arr1.push(obj)
                    }
                } else {
                    arr1.push(obj)
                }
            })
            setDataArr(arr1)
        }
    }

    useEffect(() => {
        formatData()
        sitemapGetData()
    },[])

    return (
        <Layout>
            <Meta
                files={{
                    js: [],
                    css: [
                        "/assets/css/common.css", 
                        "/assets/css/listing.css", 
                        "/assets/css/sitemap.css",
                        "/assets/css/accessibility.css"
                    ],
                }}
                tags={metaTag}
            />
            <main className="innerpage pt-first-section pt-0"> 
                <section className="Inner_banner siteMapBanner">
                    <div className="Inner_banner_content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-5 col-md-6">
                                    <div className="title_with_image">   
                                    <h1>Sitemap</h1>
                                    </div>
                                    <p>Find everything you need to make your visit as pleasant and comfortable as possible.</p> 
                                </div>
                            </div>
                        </div>   
                    </div> 
                    <picture className="hero_image text-center">
                        <source media="(min-width:1024px)"
                            srcset="/assets/images/sitemap/sitemap-banner-desktop.jpg" />
                        <source media="(min-width:300px)"
                            srcset="/assets/images/sitemap/mobile-banner-sitemap.png" />
                        <img src="/assets/images/sitemap/sitemap-banner-desktop.jpg" alt="" title="" />
                    </picture>
                </section>
                <section className="siteMapContent">
                    {
                        sitemapMenu && sitemapMenu?.map((item, index) => {
                            return(
                                <div key={index} className={`siteMapInner ${index % 2 === 0 ? '' : 'bgGary'}`}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12">
                                                { item?.relative ? <h2 className="topHeading" key={item.key}><Link to={item.relative ? item.relative : "#"}>{item.title.toUpperCase() || ''}</Link></h2> : <h2 className="topHeading" key={item.key}>{item.title.toUpperCase() || ''}</h2> }
                                                <ul>
                                                    { item?.below && item?.below.map( sub => {
                                                        return(
                                                            <li key={sub.key}>
                                                                <Link to={sub.relative ? sub.relative : "#"}>{sub.title || ''}</Link>
                                                                {
                                                                    sub && sub?.below?.length && <ul>
                                                                        {
                                                                            sub && sub?.below?.length && sub?.below?.map( inn => {
                                                                                return(
                                                                                    <li className="inn" key={inn.key}>
                                                                                        <Link to={inn.relative ? inn.relative : "#"}>{inn.title || ''}</Link>
                                                                                        {
                                                                                            inn && inn?.below?.length && <ul>
                                                                                                {
                                                                                                    inn && inn?.below?.length && inn?.below?.map( (subInn, i) => {
                                                                                                        return(
                                                                                                            <li className="subInn" key={inn.key * i}>
                                                                                                                <Link to={subInn.relative ? subInn.relative : "#"}>- {subInn.title || ''}</Link>
                                                                                                            </li>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </ul>
                                                                                        }
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                }
                                                            </li>
                                                        )
                                                    }) }
                                                </ul>
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                            )
                        })
                    }
                </section>
                
            </main>
        </Layout>
    )
}

export const query = graphql`
query MyQuery {
    allNodeTemplatePages {
      nodes {
        id
        title
        path {
          alias
        }
        metatag {
            attributes {
                content
                href
                name
                property
                rel
            }
        }
        relationships {
          field_sitemap_category {
            id
            name
          }
        }
      }
    }
    allTaxonomyTermSpeciality {
      nodes {
        field_section
        name
        id
        path {
          alias
        }
      }
    }
    allMenuItems(
        filter: {
          menu_name: { eq: "header" }
          parent: { id: { eq: null } }
        }
      ) {
        edges {
          node {
            title
            description
            url
            children {
              ... on MenuItems {
                url
                title
                description
              }
            }
          }
        }
      }
  }
`

export default Search